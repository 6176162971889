import { filters, getTodayISO } from "@libry-content/common";
import { LanguageCode } from "@libry-content/localization";
import { groq } from "next-sanity";
import {
  groqEventStart,
  groqStartDate,
  ResolvedEvent,
  resolveEventGroqProjection,
} from "../components/arrangement/sanityQuery";
import { sanityClient } from "../utils/sanity/client";
import { defaultSizes } from "./searchApi";
import { SearchEventsRequestData } from "./types";

const groqSearchMatch = groq`"*" + $searchQuery + "*"`;

const relevantEventsGroqQuery = (languageCode: LanguageCode) => groq`
  *[
    _type == "event" &&
    site._ref == $siteId &&
    (eventStatus == "available" || eventStatus == "full")
    && ${filters.eventTodayOrLater}
  ] |
  score(
    boost(title.${languageCode} match ${groqSearchMatch}, 3),
    boost(eventType.${languageCode} match ${groqSearchMatch}, 1),
    // TODO: related literature, titles and authors?
  )
  [_score > 0]
`;

const resolvedEventsGroqQuery = (languageCode: LanguageCode, size?: number, from = 0) => {
  const slice = size ? `[${from}..${size}]` : "";

  return groq`
    ${relevantEventsGroqQuery(languageCode)} |
    order(${groqStartDate} asc, ${groqEventStart} asc)
    ${slice}
    {${resolveEventGroqProjection}}`;
};

type SearchEventsResponse = { events: ResolvedEvent[]; total: number };

const eventsGroqQuery = (languageCode: LanguageCode, size: number, from: number) => groq`{
  "events": ${resolvedEventsGroqQuery(languageCode, size, from)},
  "total": count(${relevantEventsGroqQuery(languageCode)})
}`;

export const searchEvents = async (data: SearchEventsRequestData) => {
  const { searchQuery, siteId, size = defaultSizes.events, languageCode, from = 0 } = data;
  if (!searchQuery) return { events: [], total: 0 };

  const params = { today: getTodayISO(), siteId, searchQuery };
  return await sanityClient.fetch<SearchEventsResponse>(eventsGroqQuery(languageCode, size, from), params);
};

// For now we hardcode the isilNumbers for the Tysnes library special case.
const tysnesSiteId = "181621ea-9867-4046-b75d-878ee89b5749";

// For now we hardcode the sites for which we have holdings data
const siteIdsWithHoldingData = [
  tysnesSiteId,
  "b19ecdb5-e05d-482b-9172-8333300768b4", // Tønsberg og Færder bibliotek
  "b77fdf22-d14f-44e1-a152-b51ca0c901df", // Meieriet bibliotek
  "eb6cc3a4-be71-4d26-9bb7-d809ef64aa64", // Voss bibliotek
  "274efdc0-a8f0-4e00-bc3a-35272da92042", // Sel bibliotek
  "0571ee99-78be-4102-9038-825eed55daa1", // Sandnes bibliotek
  "6aec6533-3ffa-4abf-8082-d091a9b64038", // Haugesund folkebibliotek
  "f5fe77d2-1b04-404a-a0c5-d6a9459043a2", // Tysvær bibliotek
  "d569dfe5-7eab-4473-a0bb-52b5ece997ba", // Gjesdal folkebibliotek

  "c97eb0be-4ee3-4988-8a71-a9fc5713ea86", // Testbibliotek hans-Jeiger, i dataset "dev_hans-jeiger"
  "36c566b8-5ebf-4c06-b09f-86dae77fe077", // Biblioteksentralens testbibliotek, i datasett "test"
  "e2bf5d3a-6410-4eee-adc8-1a4442964f86", // test-bibliofil, i test seed som blir brukt i preview deploys
];

export const isilNumbersQuery = groq`select(
  @._id == "${tysnesSiteId}" => ["NO-2122100", "NO-2122200", "NO-2122300"], 
  select(
    @._id in ${JSON.stringify(siteIdsWithHoldingData)} =>
      *[ _type == "library" && defined(libraryNumber) ]{ "isilNumber": "NO-" + libraryNumber }.isilNumber,
    null
  )
)`;
