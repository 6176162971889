import { BibliofilAppService, objectKeys } from "@libry-content/types";
import { DigitalLibraryCustomService, Documents } from "@libry-content/types";

// ----------- Hardcoded definitions; the orders are significant -----------

export const digitalLibraryServiceCategories = {
  "library-app": {
    nb: "Bibliotekets app",
    nn: "Appen til biblioteket",
  },
  "ebooks-and-audiobooks": {
    nb: "E-bøker og lydbøker",
    nn: "E-bøker og lydbøker",
  },
  "film-services": {
    nb: "Filmtjenester",
    nn: "Filmtenester",
  },
  "music-and-podcasts": {
    nb: "Musikk og podkaster",
    nn: "Musikk og podkastar",
  },
  "newspapers-and-magazines": {
    nb: "Aviser og magasiner",
    nn: "Aviser og magasin",
  },
  "local-history-genealogy-and-archive": {
    nb: "Lokalhistorie, slektsgransking og arkiv",
    nn: "Lokalhistorie, slektsgransking og arkiv",
  },
  "other-digital-resources": {
    nb: "Andre digitale ressurser",
    nn: "Andre digitale ressursar",
  },
} as const;

export const digitalLibraryServiceTemplates = {
  bibliofilApp: "library-app",
  bookbites: "ebooks-and-audiobooks",
  verdensbiblioteket: "ebooks-and-audiobooks",
  digitaltNasjonaltLanekort: "other-digital-resources",
  bibliotekSok: "other-digital-resources",
  filmbib: "film-services",
  filmoteket: "film-services",
  nasjonalbiblioteketsNettbibliotek: "other-digital-resources",
  libby: "ebooks-and-audiobooks",
  allbok: "ebooks-and-audiobooks",
  flipp: "newspapers-and-magazines",
  idunn: "other-digital-resources",
  cineast: "film-services",
  naxos: "music-and-podcasts",
  lovdataPro: "other-digital-resources",
  pressreader: "newspapers-and-magazines",
} as const;

// ----------- Derived types -----------

export const digitalLibraryTemplateCodes = objectKeys(digitalLibraryServiceTemplates);

export type DigitalLibraryTemplateCode = (typeof digitalLibraryTemplateCodes)[number];

export const isDigitalLibraryTemplateCode = (item: unknown): item is DigitalLibraryTemplateCode =>
  typeof item === "string" && (digitalLibraryTemplateCodes as readonly string[]).includes(item);

// ----------- Templates (edited in the admin studio) -----------

export type DigitalLibraryTemplateType = `${DigitalLibraryTemplateCode}Template`;

export type DigitalLibraryTemplate = Extract<Documents, { _type: DigitalLibraryTemplateType }>;

export const digitalLibraryCodeToTemplateType = (code: DigitalLibraryTemplateCode): DigitalLibraryTemplateType =>
  `${code}Template`;

export const digitalLibraryTemplateTypes = digitalLibraryTemplateCodes.map(digitalLibraryCodeToTemplateType);

export const isDigitalLibraryTemplateType = (item: unknown): item is DigitalLibraryTemplateType =>
  typeof item === "string" && (digitalLibraryTemplateTypes as readonly string[]).includes(item);

export const isDigitalLibraryTemplate = (item: unknown): item is DigitalLibraryTemplate =>
  typeof item === "object" && isDigitalLibraryTemplateType(item?.["_type"]);

// ----------- Template services (edited in the site studio) -----------

export type DigitalLibraryTemplateServiceType = `${DigitalLibraryTemplateCode}Service`;

export type DigitalLibraryTemplateService = Extract<Documents, { _type: DigitalLibraryTemplateServiceType }>;

export const digitallLibraryCodeToServiceType = (code: DigitalLibraryTemplateCode): DigitalLibraryTemplateServiceType =>
  `${code}Service`;

export const digitalLibraryTemplateServiceTypes = digitalLibraryTemplateCodes.map(digitallLibraryCodeToServiceType);

// ----------- Custom services (edited in the site studio) -----------

export const isDigitalLibraryCustomServiceType = (item: unknown): item is DigitalLibraryCustomService["_type"] =>
  item === "digitalLibraryCustomService";

export const isDigitalLibraryCustomService = (item: unknown): item is DigitalLibraryCustomService =>
  typeof item === "object" && isDigitalLibraryCustomServiceType(item?.["_type"]);

// ----------- General services (template services and custom services together) -----------

export const digitalLibraryServiceTypes = [
  "digitalLibraryCustomService",
  ...digitalLibraryTemplateServiceTypes,
] as const;

export type DigitalLibraryServiceType = (typeof digitalLibraryServiceTypes)[number];

export type DigitalLibraryService = Extract<Documents, { _type: DigitalLibraryServiceType }>;

export const isDigitalLibraryServiceType = (item: unknown): item is DigitalLibraryServiceType =>
  typeof item === "string" && (digitalLibraryServiceTypes as readonly string[]).includes(item);

export const isDigitalLibraryService = (item: unknown): item is DigitalLibraryService =>
  typeof item === "object" && isDigitalLibraryServiceType(item?.["_type"]);

// ----------- Utils -----------

export const digitalLibraryTemplateConstantObject = <T extends unknown>(value: T) =>
  Object.fromEntries(
    digitalLibraryTemplateCodes.map((code) => [digitalLibraryCodeToTemplateType(code), value])
  ) as Record<DigitalLibraryTemplateType, T>;

export const digitalLibraryTemplateServiceConstantObject = <T extends unknown>(value: T) =>
  Object.fromEntries(
    digitalLibraryTemplateCodes.map((code) => [digitallLibraryCodeToServiceType(code), value])
  ) as Record<DigitalLibraryTemplateServiceType, T>;

export const isBibliofilAppService = <T extends Pick<BibliofilAppService, "_type">>(item: unknown): item is T =>
  typeof item === "object" && item?.["_type"] === "bibliofilAppService";
